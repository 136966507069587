const sortByDate = (direction = "ASC") => (a, b) => {
    const startDateA = a.startDate.split('/');
    const startDateB = b.startDate.split('/');

    const tbdValueMonth = direction === "ASC" ? 13 : 0;
    const tbdValueDay = direction === "ASC" ? 13 : 0;

    (startDateA[0] === 'TBD') && (startDateA[0] = tbdValueMonth);
    (startDateB[0] === 'TBD') && (startDateB[0] = tbdValueMonth);
    (startDateA[1] === 'TBD') && (startDateA[1] = tbdValueDay);
    (startDateB[1] === 'TBD') && (startDateB[1] = tbdValueDay);

    const monthA = parseInt(startDateA[0]);
    const monthB = parseInt(startDateB[0]);

    const dayA = parseInt(startDateA[1]);
    const dayB = parseInt(startDateB[1]);

    if (direction === "ASC") {
        return (monthA * 32 + dayA) - (monthB * 32 + dayB);
    } else {
        return (monthB * 32 + dayB) - (monthA * 32 + dayA);
    }

};

export {
    sortByDate
}