const conferences = [
    // 1
    {
        "id":"dotswift_2019",
        "title":"dotSwift",
        "description":"The European Swift Conference. dotConferences is a series of high-caliber developer events in Paris.",
        "startDate":"1/28/19",
        "endDate":"1/28/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Paris",
        "state":"",
        "country":"France",
        "websiteUrl":"https://www.dotswift.io/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"https://www.youtube.com/user/dotconferences",
        "facebookUrl":"https://www.facebook.com/dotconferences/",
        "twitterUrl":"https://twitter.com/dotSwift",
        "instagramUrl":"https://www.instagram.com/explore/tags/dotswift/",
        "linkedinUrl":"https://www.linkedin.com/company/dotconferences/",
        "publish":true
    },
    {
        "id":"google_developer_group_devfest_events_2019",
        "title":"Google Developer Group DevFest Events",
        "description":"There are 613 one day long DevFest events in cities around the world.",
        "startDate":"1/1/19",
        "endDate":"12/31/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"613 cities",
        "state":"",
        "country":"Around the world",
        "websiteUrl":"https://devfest.withgoogle.com/#devfest-map",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"ios_conf_sg_2019",
        "title":"iOS Conf SG",
        "description":"Single-track conference featuring 20+ pure iOS and Swift programming talks",
        "startDate":"1/17/19",
        "endDate":"1/19/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":300,
        "speakers":20,
        "exhibitors":0,
        "price":0,
        "city":"Singapore",
        "state":"",
        "country":"Singapore",
        "websiteUrl":"https://2019.iosconf.sg/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 2
    {
        "id":"mobile_world_congress_barcelona_2019",
        "title":"Mobile World Congress Barcelona",
        "description":"The world's largest exhibition for the mobile industry, incorporating a thought-leadership conference that features prominent executives representing mobile operators, device manufacturers, technology providers, vendors and content owners from across the world",
        "startDate":"2/25/19",
        "endDate":"2/28/19",
        "platforms":[
            "iOS",
            "Android"
        ],
        "languages":[],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"",
        "state":"",
        "country":"",
        "websiteUrl":"https://www.mwcbarcelona.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"droidkaigi_2019",
        "title":"droidkaigi",
        "description":"DroidKaigi is a conference tailored for Android developers.",
        "startDate":"2/7/19",
        "endDate":"2/8/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Tokyo",
        "state":"",
        "country":"Japan",
        "websiteUrl":"https://droidkaigi.jp/2019/en/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"ro_mobos_2019",
        "title":"RO MobOS",
        "description":"The Mobile Operating Systems Community Romania is intended to build a community around the field of mobile software development.",
        "startDate":"2/14/19",
        "endDate":"2/16/19",
        "platforms":[
            "Android",
            "iOS"
        ],
        "languages":[
            "Java",
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Cluj-Napoca",
        "state":"",
        "country":"Romania",
        "websiteUrl":"http://romobos.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 3
    {
        "id":"mobiletech_conference_and_summit_2019",
        "title":"MobileTech Conference and Summit",
        "description":"Less theory, more practice! For the first time MobileTechCon offers two workshop days and one conference day. The conference and training event will focus on iOS, Android and Progressive Web Apps with Angular, mobile backends and User Experience (UX)",
        "startDate":"3/25/19",
        "endDate":"3/27/19",
        "platforms":[
            "iOS",
            "Android"
        ],
        "languages":[
            "Java",
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer",
            "UX"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Munich",
        "state":"",
        "country":"Germany",
        "websiteUrl":"https://mobiletechcon.de/en/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"try!_swift_-_tokyo_2019",
        "title":"try! Swift - Tokyo",
        "description":"Swift Conference is an international community gathering about the latest advancements in Swift Development. The event is about bringing together talent from all around the world to collaborate and share advanced knowledge and techniques to improve Swift craftsmanship.",
        "startDate":"3/21/19",
        "endDate":"3/23/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Tokyo",
        "state":"",
        "country":"Japan",
        "websiteUrl":"https://www.tryswift.co/events/2019/tokyo/en/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"voxxed_days_bucharest_2019",
        "title":"Voxxed Days Bucharest",
        "description":"An international series of one day events for the Voxxed Community focusing on Java.",
        "startDate":"3/20/19",
        "endDate":"3/20/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Bucharest",
        "state":"",
        "country":"Romania",
        "websiteUrl":"https://romania.voxxeddays.com/bucharest/2019-03-20/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"voxxed_days_zurich_2019",
        "title":"Voxxed Days Zurich",
        "description":"An international series of one day events for the Voxxed Community focusing on Java.",
        "startDate":"3/19/19",
        "endDate":"3/19/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":434,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Zurich",
        "state":"",
        "country":"Switzerland",
        "websiteUrl":"https://voxxeddays.com/zurich/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"ioscon_2019",
        "title":"iOSCon",
        "description":"iOSCon celebrates the latest developments and the sharpest minds in iOS. Do you want to find out about all the latest & most topical iOS & Swift technologies and applications and discover the best practices and ideas?",
        "startDate":"3/21/19",
        "endDate":"3/22/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"London",
        "state":"",
        "country":"United Kingdom (UK)",
        "websiteUrl":"https://skillsmatter.com/conferences/10823-ioscon-2019-the-conference-for-ios-and-swift-developers",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"greachconf_2019",
        "title":"GreachConf",
        "description":"Java, JVM, and JVM Frameworks",
        "startDate":"3/28/19",
        "endDate":"3/30/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Madrid",
        "state":"",
        "country":"Spain",
        "websiteUrl":"https://www.greachconf.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 4
    {
        "id":"droidcon_turin_2019",
        "title":"droidcon Turin",
        "description":"droidcon is the premier global Android developer event, connecting a community of over 25000 developers worldwide.",
        "startDate":"4/4/19",
        "endDate":"4/5/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Turin",
        "state":"",
        "country":"Italy",
        "websiteUrl":"http://it.droidcon.com/2019/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"voxxed_days_milan_2019",
        "title":"Voxxed Days Milan",
        "description":"An international series of one day events for the Voxxed Community focusing on Java.",
        "startDate":"4/13/19",
        "endDate":"4/13/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Milan",
        "state":"",
        "country":"Italy",
        "websiteUrl":"https://voxxeddays.com/milan",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"devoxx_paris_2019",
        "title":"Devoxx Paris",
        "description":"Devoxx is the biggest worldwide Java conference with access to hundreds of remarkable international speakers, sponsors, huge content databases and new speech formats.",
        "startDate":"4/17/19",
        "endDate":"4/19/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Paris",
        "state":"",
        "country":"France",
        "websiteUrl":"http://devoxx.fr/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"codemobile_2019",
        "title":"CodeMobile",
        "description":"CodeMobile is a UK developer conference that has been created by developers who love creating apps in the mobile space. Android and iOS",
        "startDate":"4/1/19",
        "endDate":"4/1/19",
        "platforms":[
            "Android",
            "iOS"
        ],
        "languages":[
            "Java",
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Chester",
        "state":"",
        "country":"United Kingdom (UK)",
        "websiteUrl":"http://www.codemobile.co.uk/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"nsnorth_2019",
        "title":"NSNorth",
        "description":"Canada's independent Apple Developer and Designer Event",
        "startDate":"4/26/19",
        "endDate":"4/28/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer",
            "UX"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Montreal",
        "state":"",
        "country":"Canada",
        "websiteUrl":"https://nsnorth.ca/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"android_makers_2019",
        "title":"Android Makers",
        "description":"Join us in tackling the future of Android with the hottest experts of the domain. There'll be technical sessions, workshops, debates, networking, plus a chance to demo your project in the Makers Area.",
        "startDate":"4/22/19",
        "endDate":"4/23/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Paris",
        "state":"",
        "country":"France",
        "websiteUrl":"https://androidmakers.fr/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"chicago_roboto_2019",
        "title":"Chicago Roboto",
        "description":"Android Developers from around the globe descend on downtown Chicago for two amazing days!",
        "startDate":"4/25/19",
        "endDate":"4/26/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Chicago",
        "state":"IL",
        "country":"United States (USA)",
        "websiteUrl":"https://chicagoroboto.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"app_builders_2019",
        "title":"App Builders",
        "description":"Get inspired by talks from the best brains of the mobile industry. App Builders is the perfect place to meet other developers working on iOS and Android every day.",
        "startDate":"4/29/19",
        "endDate":"4/30/19",
        "platforms":[
            "Android",
            "iOS"
        ],
        "languages":[
            "Java",
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":400,
        "speakers":25,
        "exhibitors":0,
        "price":0,
        "city":"Lugano",
        "state":"",
        "country":"Switzerland",
        "websiteUrl":"https://www.appbuilders.ch/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"droidcon_boston_2019",
        "title":"droidcon Boston",
        "description":"droidcon is the premier global Android developer event, connecting a community of over 25,000 developers worldwide",
        "startDate":"4/7/19",
        "endDate":"4/8/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Berlin",
        "state":"MA",
        "country":"United States (USA)",
        "websiteUrl":"http://www.droidcon-boston.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 5
    {
        "id":"uikonf_2019",
        "title":"UIKonf",
        "description":"UIKonf features inspiring speakers giving talks on cutting edge topics in iOS development, mobile design and business.",
        "startDate":"5/26/19",
        "endDate":"5/29/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Berlin",
        "state":"",
        "country":"Germany",
        "websiteUrl":"http://www.uikonf.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"voxxed_days_minsk_2019",
        "title":"Voxxed Days Minsk",
        "description":"An international series of one day events for the Voxxed Community focusing on Java.",
        "startDate":"5/26/19",
        "endDate":"5/26/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Minsk",
        "state":"",
        "country":"Belarus",
        "websiteUrl":"https://voxxeddays.com/minsk/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"voxxed_days_melbourne_2019",
        "title":"Voxxed Days Melbourne",
        "description":"An international series of one day events for the Voxxed Community focusing on Java.",
        "startDate":"5/13/19",
        "endDate":"5/14/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":330,
        "speakers":28,
        "exhibitors":0,
        "price":0,
        "city":"Melbourne",
        "state":"",
        "country":"Australia",
        "websiteUrl":"https://australia.voxxeddays.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"voxxed_days_sydney_2019",
        "title":"Voxxed Days Sydney",
        "description":"An international series of one day events for the Voxxed Community focusing on Java.",
        "startDate":"5/16/19",
        "endDate":"5/17/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":330,
        "speakers":28,
        "exhibitors":0,
        "price":0,
        "city":"Sydney",
        "state":"",
        "country":"Australia",
        "websiteUrl":"https://australia.voxxeddays.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"devoxx_london_2019",
        "title":"Devoxx London",
        "description":"Devoxx is the biggest worldwide Java conference with access to hundreds of remarkable international speakers, sponsors, huge content databases and new speech formats.",
        "startDate":"5/8/19",
        "endDate":"5/10/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":1300,
        "speakers":130,
        "exhibitors":0,
        "price":0,
        "city":"London",
        "state":"",
        "country":"United Kingdom (UK)",
        "websiteUrl":"http://www.devoxx.co.uk/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 6
    {
        "id":"mobile_world_congress_shanghai_2019",
        "title":"Mobile World Congress Shanghai",
        "description":"the world's largest exhibition for the mobile industry, incorporating a thought-leadership conference that features prominent executives representing mobile operators, device manufacturers, technology providers, vendors and content owners from across the world.",
        "startDate":"6/26/19",
        "endDate":"6/28/19",
        "platforms":[
            "iOS",
            "Android"
        ],
        "languages":[
            ""
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Shanghai",
        "state":"",
        "country":"China",
        "websiteUrl":"https://www.mwcshanghai.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"swiftaveiro_2019",
        "title":"SwiftAveiro",
        "description":"Ten fantastic mentors will run two-hour long workshops that anyone can join. Attendees will be able to pick three workshops out of a daily total of five and a different set of workshops will be made available for each day.",
        "startDate":"6/TBD/19",
        "endDate":"6/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Aveiro",
        "state":"",
        "country":"Portugal",
        "websiteUrl":"http://swiftaveiro.xyz/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"altconf_london_2019",
        "title":"AltConf London",
        "description":"AltConf is a free, community-driven and supported event in London, alongside Apple's WWDC.",
        "startDate":"6/TBD/19",
        "endDate":"6/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"San Jose",
        "state":"CA",
        "country":"United States (USA)",
        "websiteUrl":"http://london.altconf.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"altconf_san_jose_2019",
        "title":"AltConf San Jose",
        "description":"AltConf is a free, community-driven and supported event, held in downtown San Jose alongside Apple's WWDC.",
        "startDate":"6/TBD/19",
        "endDate":"6/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"San Jose",
        "state":"CA",
        "country":"United States (USA)",
        "websiteUrl":"http://altconf.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"apple_worldwide_developers_conference_2019",
        "title":"Apple Worldwide Developers Conference",
        "description":"When technology connects with creativity, incredible ideas come to life. ",
        "startDate":"6/TBD/19",
        "endDate":"6/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"San Jose",
        "state":"CA",
        "country":"United States (USA)",
        "websiteUrl":"https://developer.apple.com/wwdc/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"devoxx_krakow_2019",
        "title":"Devoxx Krakow",
        "description":"Devoxx is the biggest worldwide Java conference with access to hundreds of remarkable international speakers, sponsors, huge content databases and new speech formats.",
        "startDate":"6/24/19",
        "endDate":"6/27/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":2500,
        "speakers":144,
        "exhibitors":0,
        "price":0,
        "city":"Krakow",
        "state":"",
        "country":"Poland",
        "websiteUrl":"http://devoxx.pl/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"addc_-_app_design_&_development_conference_2019",
        "title":"ADDC - App Design & Development Conference",
        "description":"Single-track international conference for iOS & Android developers and UX/UI designers in Barcelona, Spain.",
        "startDate":"6/26/19",
        "endDate":"6/28/19",
        "platforms":[
            "Android",
            "iOS"
        ],
        "languages":[
            "Java",
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer",
            "UX"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Barcelona",
        "state":"",
        "country":"Spain",
        "websiteUrl":"https://addconf.com/2019/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 7
    {
        "id":"360|andev_2019",
        "title":"360|AnDev",
        "description":"Bringing the Android Community to the Mile High City",
        "startDate":"7/18/19",
        "endDate":"7/19/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Denver",
        "state":"CO",
        "country":"United States (USA)",
        "websiteUrl":"http://360andev.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"droidcon_berlin_2019",
        "title":"droidcon Berlin",
        "description":"droidcon is the premier global Android developer event, connecting a community of over 25,000 developers worldwide",
        "startDate":"7/1/19",
        "endDate":"7/3/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Berlin",
        "state":"",
        "country":"Germany",
        "websiteUrl":"https://www.de.droidcon.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 8
    {
        "id":"iosdc_japan_2019",
        "title":"iOSDC Japan",
        "description":"In Japanese",
        "startDate":"8/TBD/19",
        "endDate":"8/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Tokyo",
        "state":"",
        "country":"Japan",
        "websiteUrl":"https://iosdc.jp/2018/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"/dev/world_2019",
        "title":"/dev/world",
        "description":"Australia's longest running conference for macOS and iOS developers and designers.",
        "startDate":"8/TBD/19",
        "endDate":"8/TBD/19",
        "platforms":[
            "iOS",
            "macOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer",
            "UX"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Melbourne",
        "state":"",
        "country":"Australia",
        "websiteUrl":"http://www.devworld.com.au/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"360|idev_2019",
        "title":"360|iDev",
        "description":"The Leading indie iOS/Mac (and watchOS, and tvOS) Developer conference",
        "startDate":"8/25/19",
        "endDate":"8/28/19",
        "platforms":[
            "iOS",
            "Mac",
            "tvOS",
            "watchOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Denver",
        "state":"CO",
        "country":"United States (USA)",
        "websiteUrl":"https://360idev.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 9
    {
        "id":"swift_conf_2019",
        "title":"Swift Conf",
        "description":"We are an International Cocoa/Swift Conference in the ♥ of Europe since 2012.",
        "startDate":"9/TBD/19",
        "endDate":"9/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Colonge",
        "state":"",
        "country":"Germany",
        "websiteUrl":"https://swiftconf.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"iosdevuk_2019",
        "title":"iOSDevUK",
        "description":"The UK's Best iOS Development Conference",
        "startDate":"9/2/19",
        "endDate":"9/5/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Aberystwyth",
        "state":"",
        "country":"United Kingdom (UK)",
        "websiteUrl":"https://www.iosdevuk.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"try!_swift_-_nyc_2019",
        "title":"try! Swift - NYC",
        "description":"Swift Conference is an international community gathering about the latest advancements in Swift Development. The event is about bringing together talent from all around the world to collaborate and share advanced knowledge and techniques to improve Swift craftsmanship.",
        "startDate":"9/TBD/19",
        "endDate":"9/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"New York City (NYC)",
        "state":"NY",
        "country":"United States (USA)",
        "websiteUrl":"https://www.tryswift.co/events/2019/nyc/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"droidcon_vienna_2019",
        "title":"droidcon Vienna",
        "description":"droidcon is the premier global Android developer event, connecting a community of over 25,000 developers worldwide",
        "startDate":"9/19/19",
        "endDate":"9/20/19",
        "platforms":[
            "iOS Android"
        ],
        "languages":[
            "Java Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Vienna",
        "state":"",
        "country":"Austria",
        "websiteUrl":"https://droidcon.at/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 10
    {
        "id":"mobile_world_congress_americas_2019",
        "title":"Mobile World Congress Americas",
        "description":"the world's largest exhibition for the mobile industry, incorporating a thought-leadership conference that features prominent executives representing mobile operators, device manufacturers, technology providers, vendors and content owners from across the world.",
        "startDate":"10/22/19",
        "endDate":"10/24/19",
        "platforms":[
            "iOS",
            "Android"
        ],
        "languages":[
            ""
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Los Angeles",
        "state":"CA",
        "country":"United States (USA)",
        "websiteUrl":"https://www.mwcamericas.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"swift_by_northwest_2019",
        "title":"Swift by Northwest",
        "description":"At Swift by Northwest, you'll be learning from some of the best technical presenters in the Apple developer community, in one of the most beautiful regions of the country.",
        "startDate":"10/TBD/19",
        "endDate":"10/TBD/19",
        "platforms":[
            "iOS",
            "macOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Portland",
        "state":"OR",
        "country":"United States (USA)",
        "websiteUrl":"https://swiftbynorthwest.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"droidcon_london_2019",
        "title":"Droidcon London",
        "description":"droidcon is the premier global Android developer event, connecting a community of over 25,000 developers worldwide",
        "startDate":"10/24/19",
        "endDate":"10/25/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"London",
        "state":"",
        "country":"United Kingdom (UK)",
        "websiteUrl":"http://uk.droidcon.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 11
    {
        "id":"android_dev_summit_2019",
        "title":"Android Dev Summit",
        "description":"2019 schedule not out yet",
        "startDate":"11/TBD/19",
        "endDate":"11/TBD/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Mountain View",
        "state":"CA",
        "country":"United States (USA)",
        "websiteUrl":"https://developer.android.com/dev-summit/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"swift_heros_2019",
        "title":"Swift Heros",
        "description":"Swift Heroes is a new event bringing together all parts of the Apple developer value chain to share, learn and network.",
        "startDate":"11/TBD/19",
        "endDate":"11/TBD/19",
        "platforms":[
            "iOS",
            "Mac",
            "tvOS",
            "watchOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Turin",
        "state":"",
        "country":"Italy",
        "websiteUrl":"https://swiftheroes.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"devoxx_antwerp_2019",
        "title":"Devoxx Antwerp",
        "description":"Devoxx is the biggest worldwide Java conference with access to hundreds of remarkable international speakers, sponsors, huge content databases and new speech formats.",
        "startDate":"11/4/19",
        "endDate":"11/8/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Antwerp",
        "state":"",
        "country":"Belgium",
        "websiteUrl":"https://devoxx.be/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"devoxx_kyiv_2019",
        "title":"Devoxx Kyiv",
        "description":"Devoxx is the biggest worldwide Java conference with access to hundreds of remarkable international speakers, sponsors, huge content databases and new speech formats.",
        "startDate":"11/1/19",
        "endDate":"11/2/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":65,
        "exhibitors":0,
        "price":0,
        "city":"Kyiv",
        "state":"",
        "country":"Ukraine",
        "websiteUrl":"https://devoxx.org.ua/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"mobile_era_2019",
        "title":"Mobile Era",
        "description":"Mobile Era is a conference about mobile development. Our topics: iOS / Android, Mobile web / PWA, Cross-platform / JS-to-Native, IoT / Hardware, Mobile UX / Accessibility",
        "startDate":"11/7/19",
        "endDate":"11/8/19",
        "platforms":[
            "iOS",
            "Android"
        ],
        "languages":[
            "Java Swift"
        ],
        "frameworks":[],
        "topics":[
            "IoT",
            "JS-to-Native"
        ],
        "roles":[
            "Engineer",
            "UX"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Oslo",
        "state":"",
        "country":"Norway",
        "websiteUrl":"https://mobileera.rocks/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"droidcon_sf_2019",
        "title":"Droidcon SF",
        "description":"droidcon is the premier global Android developer event, connecting a community of over 25,000 developers worldwide",
        "startDate":"11/18/19",
        "endDate":"11/19/19",
        "platforms":[
            "Android"
        ],
        "languages":[
            "Java"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":800,
        "speakers":70,
        "exhibitors":0,
        "price":0,
        "city":"San Francisco",
        "state":"CA",
        "country":"United States (USA)",
        "websiteUrl":"https://www.sf.droidcon.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // 12
    {
        "id":"functional_swift_conference_2019",
        "title":"Functional Swift Conference",
        "description":"2019 yet to be announced",
        "startDate":"12/TBD/19",
        "endDate":"12/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"New York City",
        "state":"NY",
        "country":"United States (USA)",
        "websiteUrl":"http://2018.funswiftconf.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"frenchkit_2019",
        "title":"FrenchKit",
        "description":"FrenchKit is the first iOS and macOS conference in France. Focusing on a wide range of subjects, from the Cocoa APIs to Swift, covered by some of the most prominent developers in the international community.",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS",
            "macOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Paris",
        "state":"",
        "country":"France",
        "websiteUrl":"https://frenchkit.fr/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    // TBD / unconfirmed
    {
        "id":"teki_con_2019",
        "title":"Teki Con",
        "description":"Get a taste of Hawaii in Hotlanta at Teki Con. TBD on second annual conference.",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Atlanta",
        "state":"GA",
        "country":"United States (USA)",
        "websiteUrl":"https://teki-con.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"iosdevcamp_colorado_2019",
        "title":"iOSDevCamp Colorado",
        "description":"An informal conference event for Colorado iOS developers",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"TBD",
        "state":"CO",
        "country":"United States (USA)",
        "websiteUrl":"http://iosdevcampcolorado.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"iosdevcampdc_2019",
        "title":"iOSDevCampDC",
        "description":"iOSDevCampDC 2018 is a one-day, one-track conference focused on iPhone and iPad development in the Washington, DC area.",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Washington, D.C.",
        "state":"Washington, D.C.",
        "country":"United States (USA)",
        "websiteUrl":"https://iosdevcampdc.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"pragma_conference_2019",
        "title":"Pragma Conference",
        "description":"The Italian iOS/macOS/watchOS/tvOS Developers Conference",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS",
            "Mac",
            "tvOS",
            "watchOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Verona",
        "state":"",
        "country":"Italy",
        "websiteUrl":"https://pragmaconference.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"the_swift_alps_2019",
        "title":"The Swift Alps",
        "description":"An experimental event taking place in the Swiss Alps.",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Crans-Montana",
        "state":"",
        "country":"Switzerland",
        "websiteUrl":"https://theswiftalps.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
    {
        "id":"rwdevcon_2019",
        "title":"RWDevCon",
        "description":"RWDevCon is a conference focused on high quality programming tutorials. It’s right outside Washington DC, brought to you by the teams at raywenderlich.com and 360iDev.",
        "startDate":"TBD/TBD/19",
        "endDate":"TBD/TBD/19",
        "platforms":[
            "iOS"
        ],
        "languages":[
            "Swift"
        ],
        "frameworks":[],
        "topics":[],
        "roles":[
            "Engineer"
        ],
        "attendees":0,
        "speakers":0,
        "exhibitors":0,
        "price":0,
        "city":"Washington, D.C.",
        "state":"Washington, D.C.",
        "country":"United States (USA)",
        "websiteUrl":"https://www.rwdevcon.com/",
        "registerUrl":"",
        "featuredSpeakersUrl":"",
        "scheduleUrl":"",
        "youtubeUrl":"",
        "facebookUrl":"",
        "twitterUrl":"",
        "instagramUrl":"",
        "linkedinUrl":"",
        "publish":true
    },
];

// https://www.hackingwithswift.com/articles/48/top-swift-and-ios-conferences-in-2018 (DONE)
// https://tidbits.com/2018/05/21/20-conferences-for-mac-and-ios-professionals-in-2018/ (DONE)
// https://github.com/jpmartha/iOS-Conferences (DONE)
// https://bugfender.com/conferences/ (DONE)
// https://www.raywenderlich.com/216-top-10-ios-conferences-in-2018 (DONE)
// https://androidstudygroup.github.io/conferences/ (DONE), could do GDG in detail
// https://confs.tech/android (DONE)

// PROMOTE SITE HERE
// https://www.quora.com/What-are-the-top-Android-development-conferences-worldwide

// United States (USA)
// United Kingdom (UK)
var mobile = [
    {
        title: "",
        description: "",
        url: "",
        city: "",
        state: "",
        country: "",
        start: "",
        end: "",
        platforms: ["iOS", "Android"],
        languages: ["Java", "Swift"],
        roles: ["Engineer"],
        attendees: 0,
        speakers: 0,
        exhibitors: 0,
    },
];

export default conferences;




















