// tslint:disable:no-http-string
import * as React from 'react';
import { css } from 'react-emotion';
import Select from 'react-select';
import Filter from '../Filter';

const platforms = [
  { value: 'ios', label: 'iOS' },
  { value: 'android', label: 'Android' },
  { value: 'mac', label: 'Mac' },
  { value: 'windows', label: 'Windows' },
  { value: 'linux', label: 'Linux' },
];

const languages = [
  { value: 'javascript', label: 'JavaScript' },
  { value: 'ruby', label: 'Ruby' },
  { value: 'java', label: 'Java' }
];

const filters = [
  // { id: 'platform', label: 'Platforms', options: platforms },
  // { id: 'language', label: 'Languages', options: languages },
  // { id: 'role', label: 'Role', options: languages },
  // { id: 'size', label: 'Size', options: languages },
  // { id: 'month', label: 'Month', options: languages },
  // { id: 'price', label: 'Price', options: languages },
  // // { id: 'difficulty', label: 'Difficulty to get in', options: languages },
  // { id: 'country', label: 'Country', options: languages },
  // { id: 'state', label: 'State', options: languages },
  // { id: 'city', label: 'City', options: languages },
];

const FilterSelect = css`
  width: 150px;
  margin-bottom: 15px;
`;

const FilterSelectWithValue = css`
  width: 250px;
`;

class Filters extends React.Component {
  constructor(props: SiteNavProps) {
    super(props);
    // @TODO - consider backing up to local storage?
    this.state = { selectedOptions: {} };
  }

  handleChange = (id) => (selectedOption) => {
    console.log(selectedOption);
    const selectedOptions = {...this.state.selectedOptions};
    selectedOptions[id] = selectedOption;

    // this.props.incrementOn(1);
    this.setState({ selectedOptions });
  }

  render() {
    const { selectedOptions } = this.state;
    return (
      <div>
        {filters.map((filter) => {
          return (
            <Filter key={filter.id} />
          );
        })}
      </div>
    );
  }
}

export default Filters;
