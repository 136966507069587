// tslint:disable:no-http-string
import * as React from 'react';
import { css } from 'react-emotion';
import Select from 'react-select';

const roles = [
    { value: 'engineer', label: 'Engineer' },
    { value: 'ux', label: 'UX' },
];

const FilterSelect = css`
  width: 150px;
  margin-bottom: 15px;
`;

const FilterSelectWithValue = css`
  width: 250px;
`;

class Filters extends React.Component {
    // handleChange = (id) => (selectedOption) => {
    //     console.log(selectedOption);
    //     const selectedOptions = {...this.state.selectedOptions};
    //     selectedOptions[id] = selectedOption;
    //
    //     // this.props.incrementOn(1);
    //     this.setState({ selectedOptions });
    // }

    render() {
        // const { selectedOptions } = this.state;
        const { id, label, options, onChange, activeFilters } = this.props;
        const hasActiveFilters = activeFilters.length > 0;
        return (
            <div key={id} className={`${FilterSelect} ${hasActiveFilters ? FilterSelectWithValue : ''}`}>
                {hasActiveFilters ? <span>{label}</span> : null}
                <Select
                    placeholder={label}
                    value={activeFilters}
                    onChange={onChange}
                    options={options}
                    isMulti={true}
                    isSearchable={true}
                />
            </div>
        );
    }
}

export default Filters;
