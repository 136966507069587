import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Select from 'react-select';
import { StickyContainer, Sticky } from 'react-sticky';
import { css } from 'react-emotion';
import Helmet from 'react-helmet';
import { compose, withStateHandlers } from 'recompose';

import { sortByDate } from '../lib/sort';

import Footer from '../components/Footer';
import ConferenceCard from '../components/ConferenceCard';
import SiteNav from '../components/header/SiteNav';
import Filters from '../components/header/Filters';
import Filter from '../components/Filter';
import Sort from '../components/header/Sort';
// import PostCard from '../components/PostCard';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import config from '../website-config';
import conferenceData from '../content/conference-data';
import {
  inner,
  outer,
  // PostFeed,
  // PostFeedRaise,
  SiteDescription,
  IndexSiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';

const FiltersSearchSort = css`
  max-width: 300px;
  width: 300px;
  margin-top: -20px;
  padding: 10px 10px;
`;

const Conferences = css`
  max-width: 600px;
  width: 600px;
  margin-top: -20px;
  padding: 10px 10px;
`;

const FlexContainer = css`
  display: flex;
  flex-wrap: nowrap;
`;

const FlexChild = css`
  background-color: white;
  margin: 10px;
`;

// const NavLink = props => {
//     if (!props.isFirstLastOrCurrent) {
//         return <Link to={props.url}>{props.text}</Link>;
//     }
//
//     return <span>{props.text}</span>;
// };

// I like 6n + 3 and 5n + 4 here
const HomeConferenceList = css`
`;

// const PaginationWrapper = css`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   margin-bottom: 25px;
//
//   > * {
//     margin: 0px 5px;
//
//     &:first-child, &:last-child {
//         margin: 0px 10px;
//     }
//   }
// `;

export interface IndexProps {
    data: {
        logo?: {
            childImageSharp: {
                fixed: any;
            };
        };
        header: {
            childImageSharp: {
                fluid: any;
            };
        };
    };
}


const IndexPage: React.SFC<IndexProps> = props => {
    // const { index } = props.pathContext;
    // const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
    // const nextUrl = (index + 1).toString();
    // const paginationElements = [];
    // if (pageCount > 1) {
    //     paginationElements.push(<div key={'previousLink'} className="previousLink">
    //         <NavLink isFirstLastOrCurrent={first} url={previousUrl} text="Go to Previous Page" />
    //     </div>);
    //
    //     for (const x of Array(pageCount).keys()) {
    //         paginationElements.push(
    //             <NavLink isFirstLastOrCurrent={index - 1 === x} url={x === 0 ? "" : (x+1).toString()} text={x + 1} />
    //         );
    //     }
    //     paginationElements.push(<div key={'nextLink'} className="nextLink">
    //         <NavLink isFirstLastOrCurrent={last} url={nextUrl} text="Go to Next Page" />
    //     </div>);
    // }

    const { onRoleFilterChange, roleFilters, conferences } = props;

    console.log(conferences);

    const displayConferences = conferences.filter((conference) => conference.publish && !conference.filteredOut);

    const width = props.data.header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
    const height = String(Number(width) / props.data.header.childImageSharp.fluid.aspectRatio);
    return (
        <IndexLayout className={`${HomeConferenceList}`}>
          <Helmet>
            <title>{config.seoTitle}</title>
            <meta name="description" content={config.seoDescription} />

            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="#D0E0D8" />
            <meta name="apple-mobile-web-app-title" content="Lazywill" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-57x57.png" sizes="57x57" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-60x60.png" sizes="60x60" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-72x72.png" sizes="72x72" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-76x76.png" sizes="76x76" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-114x114.png" sizes="114x114" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-120x120.png" sizes="120x120" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-144x144.png" sizes="144x144" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-152x152.png" sizes="152x152" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-180x180.png" sizes="180x180" />
            <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />

            <meta property='article:author' content={config.facebookUrl} />
            <meta property="article:publisher" content={config.facebookUrl} />

            <meta property="og:site_name" content={config.title} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={config.title} />
            <meta property="og:description" content={config.description} />
            <meta property="og:url" content={config.siteUrl} />
            <meta property="og:image" content={props.data.header.childImageSharp.fluid.src} />
            <meta property="og:image:width" content={width} />
            <meta property="og:image:height" content={height} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={config.title} />
            <meta name="twitter:description" content={config.description} />
            <meta name="twitter:url" content={config.siteUrl} />
            <meta name="twitter:image" content={props.data.header.childImageSharp.fluid.src} />
            <meta name="twitter:site" content={config.twitterHandle} />

            {/*<meta property="fb:app_id" content="1234" />*/}
          </Helmet>
          <Wrapper>
            <header
                className={`${IndexSiteHeader} ${outer}`}
                style={{
                    // backgroundImage: `url('${props.data.header.childImageSharp.fluid.src}')`,
                }}
            >
              <div className={`${inner}`}>
                <SiteNav isHome={false} />
                {/**/}
                {/*<Sort />*/}
              </div>
            </header>

              <div className={FlexContainer}>
                  <div className={FlexChild}>
                      <div className={FiltersSearchSort}>
                          <h2>Select your main filter</h2>
                          <Filter
                              id={'role'}
                              label={'Main Filter'}
                              options={[{ value: 'Engineer', label: 'Engineer' }, { value: 'UX', label: 'UX' }]}
                              onChange={onRoleFilterChange}
                              activeFilters={roleFilters}
                          />
                          {/*Engineer / Developer / QA (AI, ML, Data Science, Big Data, Frontend, Mobile, Backend, Analytics, BI, IoT, Robotics, Quality Assurance, Test Automation, SysAdmin, Networking)*/}
                          {/*- IT*/}
                          {/*- Product Manager*/}
                          {/*- UX / Design*/}
                          {/*- Founder / Exec*/}
                          {/*- Business Innovation*/}
                          {/*- Emerging Tech / Consumer Technology*/}
                          {/*- Futurist*/}
                          {/*- Sales*/}
                          {/*- Startup / VC*/}
                          {/*- Customer Success*/}
                          {/*- Marketing / SEO*/}
                          {/*- Finance / FinTech*/}
                          {/*- Recruiter / HR*/}
                          {/*- Events*/}
                          <h2>Sort by (coming soon)</h2>
                          Date
                          - Attendees
                          - Price
                          {/*Date / Size / Price*/}
                          <h2>Additional filters (coming soon)</h2>
                          {/*Topic - General, A bit of everything, AI, 5G, iOS, Android, Windows, Gaming*/}
                          {/*Programming Language - Swift, Java, JavasScript*/}
                          {/*Framework - Vue.js, React, Angular*/}
                          {/*Type - Conference, Trade Show, Summit*/}
                          {/*Language - English, Spanish, Chinese*/}
                          {/*Price Range*/}
                          {/*Date picker*/}
                          <Filters />

                          <h2>Why use Dev Meets Conf?</h2>
                          <p>
                              Searching for conferences is a lot of work - that's why we've spent countless
                              hours scouring the internet, curating a list of the best conferences with the
                              information you care about, and added sorting and filtering to make choosing your
                              next conference a breeze.
                          </p>
                      </div>
                  </div>
                  <div className={FlexChild}>
                      <div className={Conferences}>
                          <h2>Upcoming 2019 Conferences - {displayConferences.length}</h2>
                          {displayConferences.map((conference) => {
                              console.log(conference.filteredOut);
                              
                              return (
                                  <ConferenceCard
                                    key={conference.id}
                                    conference={conference}
                                  />
                              )
                            })
                          }
                      </div>
                  </div>
              </div>

            {props.children}
            {/*<div className={`${PaginationWrapper}`}>*/}
                {/*{paginationElements}*/}
            {/*</div>*/}
            <Footer />
          </Wrapper>
        </IndexLayout>
    );
};

export default compose(
    withStateHandlers(
        () => {
            // Can pull in a sort saved from last session or LS if we want
            const conferences = conferenceData.sort(sortByDate('ASC'));

            console.log(conferences);

            return {
                conferences,
                roleFilters: [],
            };
        },
        {
            onRoleFilterChange: ({ conferences, roleFilters }) => (value) => {
                const filters = value.map((filter) => filter.label);

                conferences.forEach((conference) => {
                    console.log(conference.roles);

                    // console.log()


                    if (filters.length === 0 || conference.roles.some((role) => -1 !== filters.indexOf(role))) {
                        console.log('not filtered out');
                        conference.filteredOut = false;
                    } else {
                        conference.filteredOut = true;
                        console.log('filtered out');
                    }
                });

                return {
                    roleFilters: value,
                    // conferences: [...conferences],
                }
            },
        }
    )
)(IndexPage);

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "avatars/avatar_400_400.png" }) {
      childImageSharp {
      # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed {
          ...GatsbyImageSharpFixed
          }
      }
    }
    header: file(relativePath: { eq: "img/ivan-diaz-452850-unsplash.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;