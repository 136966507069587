import { Link } from 'gatsby';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import Website from './icons/website.png'; // https://www.iconfinder.com/mrafliy
import Twitter from './icons/twitter.png'; // https://www.iconfinder.com/thepinkgroup
import Facebook from './icons/facebook.png'; // https://www.iconfinder.com/zlaten
import Instagram from './icons/instagram.png'; // From IG website as favicon
import People from './icons/people.png'; // https://www.iconfinder.com/yeasirakash
import City from './icons/city.png'; // https://www.iconfinder.com/arunxthomas
import Calendar from './icons/calendar.png'; // https://www.iconfinder.com/pocike

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import { colors } from '../styles/colors';

const ConferenceCardSection = styled.section`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #e6ecf0;
  padding: 5px;
  
  :hover {
    background-color: #f5f8fa;
  }
`;

const ConferenceCardName = styled.h4`
  margin: 8px 0 2px 0;
  padding: 0;
  font-size: 2rem;

  a {
    color: ${colors.darkgrey};
    font-weight: 700;
  }

  a:hover {
    text-decoration: none;
  }
`;

const ConferenceCardContent = styled.section`
  p {
    margin: 8px 0;
    color: ${colors.midgrey};
    line-height: 1.3em;
  }
`;

const InfoMarginRight = css`
  margin-right: 7px;
`;

const ConferenceSocialIcon = css`
  margin-right: 7px;
`;

export interface ConferenceCardProps {
  author: any;
}

const socialIconImageSize = '20px';

const ConferenceCard: React.SFC<ConferenceCardProps> = ({ conference }) => {
  const hasStateOrCountry = !!(conference.state || conference.country) && conference.city !== conference.country;
  return (
    <ConferenceCardSection>
      {/*<img className={`${AuthorProfileImage}`} src={''} alt={author.id} />*/}
      <ConferenceCardContent>
        <ConferenceCardName>
            <a
                href={conference.websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                {conference.title}
            </a>
          {/*<Link to={`/conference/${conference.id}/`}></Link>*/}
        </ConferenceCardName>
        {conference.description && (
          <p>{conference.description}</p>
        )}
          {!!conference.city && (
              <span className={`${InfoMarginRight}`}><img src={City} height={socialIconImageSize} />&nbsp;{conference.city}{hasStateOrCountry && `, ${conference.state || conference.country}`}</span>
          )}
          {!!conference.startDate && (
              <span className={`${InfoMarginRight}`}><img src={Calendar} height={socialIconImageSize} />&nbsp;{conference.startDate}&nbsp;-&nbsp;{conference.endDate}</span>
          )}
          {conference.attendees > 0 && (
              <span className={`${InfoMarginRight}`}><img src={People} height={socialIconImageSize} />&nbsp;{numberWithCommas(conference.attendees)} attendees</span>
          )}
          <br />
          {conference.websiteUrl && (
              <a
                  className={`${ConferenceSocialIcon}`}
                  href={conference.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  <img src={Website} height={socialIconImageSize} />
              </a>
          )}
          {conference.twitterUrl && (
              <a
                  className={`${ConferenceSocialIcon}`}
                  href={conference.twitterUrl}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  <img src={Twitter} height={socialIconImageSize} />
              </a>
          )}
          {conference.facebookUrl && (
              <a
                  className={`${ConferenceSocialIcon}`}
                  href={conference.facebookUrl}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  <img src={Facebook} height={socialIconImageSize} />
              </a>
          )}
          {conference.instagramUrl && (
              <a
                  className={`${ConferenceSocialIcon}`}
                  href={conference.instagramUrl}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  <img src={Instagram} height={socialIconImageSize} />
              </a>
          )}
          {/* Add YouTube icon */}
      </ConferenceCardContent>
    </ConferenceCardSection>
  );
};

export default ConferenceCard;
