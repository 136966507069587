import ai from './conference-data-2019/ai';
import cSharp from './conference-data-2019/c-sharp';
import cloudDevOps from './conference-data-2019/cloud-devops';
import dataScience from './conference-data-2019/data-science-big-data';
import databases from './conference-data-2019/databases';
import diverseGeneric from './conference-data-2019/diverse-generic';
import frontend from './conference-data-2019/frontend';
import gaming from './conference-data-2019/gaming';
import go from './conference-data-2019/go';
import iot from './conference-data-2019/iot';
import it from './conference-data-2019/it';
import java from './conference-data-2019/java';
import machineLearning from './conference-data-2019/machine-learning';
import mobile from './conference-data-2019/mobile';
import openSource from './conference-data-2019/open-source';
import php from './conference-data-2019/php';
import productManager from './conference-data-2019/product-manager';
import remoteWork from './conference-data-2019/remote-work';
import security from './conference-data-2019/security';
import vr from './conference-data-2019/vr';

// Non Dev
import consumer from './conference-data-2019/non-dev/consumer';
import vendor from './conference-data-2019/non-dev/vendor';

const conferences2019 = [].concat(
    mobile,
    // frontend,
    // ai,
    // machineLearning,
    // dataScience,
    // security,
    // cloudDevOps,
    // databases,
    // iot,
    // vr,
    // gaming,
    // it,
    // openSource,
    // go,
    // php,
    // cSharp,
    // java,
    // diverseGeneric,
    // consumer,
    // vendor,
    // productManager,
    // remoteWork,
);

// Goals
// Fill out descriptions for these 100
// Make main filter work for these 100
// Make and promote top 10 javascript conferences for 2019
// Compress those images

export default conferences2019;